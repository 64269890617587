@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NotoSansKR";
  src: url("./assets/fonts/NotoSansKR-Bold.otf") format("woff");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansKR";
  src: url("./assets/fonts/NotoSansKR-Medium.otf") format("woff");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansKR";
  src: url("./assets/fonts/NotoSansKR-Regular.otf") format("woff");
  font-weight: 400;
  font-display: swap;
}
